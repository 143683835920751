import React, { useState } from "react";

export const Game = ({ title, image, slug }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const gameSrc = `https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=${slug}&lang=en_CA&practice=1&channel=desktop&demo=2`

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="portfolio-item">
      <div className="hover-bg" onClick={openModal} style={{ cursor: "pointer" }}>
        <div className="hover-text">
          <h4>{title}</h4>
        </div>
        <img src={image} className="img-responsive" alt={title} />
      </div>

      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="modal-close" onClick={closeModal}>
              &times;
            </button>
            <iframe id="game" title={title} frameborder="0" allow="autoplay" width="100%" height="100%" allowfullscreen="" seamless="" scrolling="no" src={gameSrc}></iframe>
          </div>
        </div>
      )}
    </div>
  );
};

