import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div id="footer">
      <div className="container text-center">
        <p>
        2024 &copy; <a href="https://odelemon.com/">Odelemon</a> inc. All rights reserved. <br/>
          
          <Link to="/privacy-policy">Privacy Policy</Link> |  <Link to="/terms">Terms and Conditions</Link>
        </p>
      </div>
    </div>
  )
}

export default Footer