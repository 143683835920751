import React from 'react'

function Disclaimer() {
  return (
    <div className="disclaimer">
        <div className="container">
            <div className="col-md-8">
                <div className="row">
                    <h2><img src='img/18.png' width="30" alt="18+ only"/> Disclaimer:</h2>
                    <p>This website is a free social gaming platform created purely for entertainment purposes. No real money is involved, and no real money can be won. All games on this site are intended for users aged 18 and above. ODELEMON does not endorse or promote gambling in any form. Please enjoy our games responsibly and for recreational purposes only.</p>
            <div className="col-md-8 pt-20">
                <a href="https://www.gambleaware.org/" target='_blank' >
                    <img src="img/gamCare.png" width="60" alt="GamCare" />
                </a>
                <a className='my-3' href="https://www.gambleaware.org/" target='_blank' >
                    <img src="img/BeAware.png" width="350" alt="GambleAware" />
                </a>
            </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Disclaimer