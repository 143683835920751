import React, {useState, useEffect} from "react";
import JsonData from "../data/data.json";
import { Game } from "../components/game";

export const PopularGames = () => {
    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
      setLandingPageData(JsonData);
    }, []);


  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Try the most popular games</h2>
          <p>
            Dive into an engaging world of color, shape, and design—play, learn, and create.
          </p>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {landingPageData.Games
              ? landingPageData.Games.sort(() => Math.random() - 0.5).slice(0,6).map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                  >
                    <Game
                      title={d.title}
                      image={d.image}
                      slug={d.slug}
                    />
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>
    </div>
  );
};
